import React from 'react'
import legalStyles from './legal.module.scss'

const Legal = (props) => {
    return (
        <section className={"container " + legalStyles.legal__container} >     
            {props.children}
        </section>
    )
}

export default Legal