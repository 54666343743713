import React from 'react'
import subHeaderStyles from './sub-header.module.scss'

const SubHeader = (props) => {
    let span

    if (props.span) {
        span = <span> {props.span}</span>
    }

    return (
        <section className={subHeaderStyles.subheader__welcome}>
            <h1>{props.title}{span}</h1>
        </section>
    )
}

export default SubHeader