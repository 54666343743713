import React from 'react'

import Store from '../components/store'
import Head from '../components/head'
import Navigation from '../components/navigation'
import Header from '../components/header'
import SubHeader from '../components/sub-header'
import Legal from '../components/legal'
import Talk from '../components/lets-talk'
import Footer from '../components/footer'
import Cookies from '../components/cookies'

const Terms = () => {
    return (
        <Store>
            <Head title="Terms of Service" />
            <Navigation />
            <Header disable />
            <SubHeader title="Terms of Service" />
            <Legal>
                <h4>Account Setup</h4>

                <p>We will setup your account after we have received payment and we and/or our payment partner(s) have screened the order(s) in case of fraud. It is your responsibility to provide us with a valid email address. You agree to provide Host3 with accurate and complete contact information.</p>

                <h4>Content</h4>

                <p>All services provided by Host3 may only be used for lawful purposes. This includes, but not limited to: music, videos, applications, photographs or any other copyrighted material. The customer agrees to indemnify and hold harmless Host3 from any claims resulting from the use of our services.</p>

                <p>Examples of unacceptable material:</p>

                <ul>
                    <li>IRC Bots</li>
                    <li>Proxy Scripts</li>
                    <li>Warez Sites</li>
                    <li>Pirated Software</li>
                    <li>Hate sites</li>
                    <li>Hack programs and archives</li>
                    <li>Sites promoting illegal activities</li>
                    <li>Pornography/Nudity/Adult Content</li>
                </ul>

                <p>Host3 will be the sole arbiter as to what constitutes a violation of this provision, and reserves the right to deactivate and remove any site at any time for any reason without refund.</p>

                <h4>Server Resource Usage</h4>

                <p>All Host3 packages come with server resource limits, this is ensure all customers on our servers receive a high quality service and are less likely to be affeted by other customers. Unless otherwise stated, packages will have limits of a maximum of 1 CPU core and 1gb of ram. If for any reason your account contiously uses it's allocated resource usage for an extended period of time, Host3 will investigate the cause and may be required to suspened the account depending on the type of usage (security issues, attacks, poor code practices etc). In some cases accounts will grow to require something more than a hosting environment, we will always work with you to find the most suitable package or solution for your website and ensure there are no disruptions to your service.</p>

                <h4>Acceptable Usage</h4>

                <p>Like all Web Hosting packages a fair usage policy applies. Abuse of an "unlimited" service or feature to the extent that it detrimentally affects other customers' services will be treated in the same manner, such abuse to be assessed and decided upon at Host3's sole discretion. We ask customers who intend to use high volumes of data to first discuss their requirements with us prior to creating an account.</p>

                <h4>SPAM and Unsolicited Commercial Email (UCE)</h4>

                <p>Host3 takes a zero tolerance approach to the sending of Unsolicited Commercial Email (UCE) or spam over our network. "Safe lists" and purchased lists will be treated as spam. Any user who sends out spam will have their account terminated without notice or refund.</p>

                <p>Host3 reserves the right to require changes or disable as necessary any web site, account, database, or other component that does not comply with this policy, at its sole discretion. Host3 also reserves the right to make any such modifications in an emergency at our sole discretion.</p>

                <p>Host3 reserves the right to charge the holder of the account used to send any unsolicited e-mail a clean up fee. This cost of the clean up fee is left entirely to the discretion of Host3.</p>

                <h4>Payment Information</h4>

                <p>You agree to supply appropriate payment for the services received from Host3, in advance of the time period during which such services are provided. You agree that until and unless you notify Host3 of your desire to cancel any or all services received, those services will be billed on a recurring basis.</p>

                <p>The amount you pay for hosting will never increase from the date of purchase. We reserve the right to change prices listed on Host3.</p>

                <h4>Backups and Data Loss</h4>

                <p>All Host3 packages have a backup service as part of our packages, unless stated they will be backed up daily to an offsite provider</p>

                <p>Backups are kept for a maximum of 30 days before being removed</p>

                <p>High Avalability clients have additional backups taken every 6 hours, these however are not accessible, if you require an immediate restore please contact our support team.</p>

                <p>Although Host3 make every measure to ensure your sites websites are backed up, your use of the service is at your sole risk. Host3 is not responsible for files and data residing on your account. You agree to take full responsibility for files and data transferred and to maintain all appropriate backup of files and data stored on Host3 servers.</p>

                <p>Host3 always recommend multiple points of backup for important data, we will happily work with you to setup any kind of additional restore points that you require in addition to our backup services.</p>

                <h4>Cancellation and Refunds</h4>

                <p>Customers may cancel at any time. Host3 gives you an unconditional 30 day money back guarantee on managed web hosting packages. Outside of the packages listed on our website, bespoke solutions cannot be refunded due to the additional costs incured by us to create them, but can be cancelled at any time. Domain registration and transfer sales to Host3 cannot be refunded but are eligible for transfer.</p>

                <p>Host3 reserves the right to cancel the account at any time. Users in breach of our terms and conditions relating to unacceptable material and having accepted our terms and conditions will not be eligible for refund under any circumstances.</p>

                <p>Only first-time accounts are eligible for a refund. For example, if you've had an account with us before, cancelled and signed up again, you will not be eligible for a refund or if you have opened a second account with us.</p>

                <h4>Resource Usage</h4>

                <p>Users may not initiate the following:</p>

                <ul>

                    <li>Use 25% or more of system resources for longer then 90 seconds. </li>

                    <li>Run any type of interactive real-time chat applications that require server resources. Remotely-hosted services are fully allowed.</li>

                    <li> Run stand-alone, unattended server-side processes at any point in time on the server. This includes any and all daemons, such as IRCD.</li>

                    <li> Run any software that interfaces with an IRC (Internet Relay Chat) network.</li>

                    <li> Run any bit torrent.</li>
                </ul>

                <h4>Bandwidth Usage</h4>

                <p>You are allocated a monthly bandwidth allowance. This allowance varies depending on the hosting package you purchase. Should your account pass the allocated amount we reserve the right to suspend the account until the start of the next allocation. Additional bandwidth can be purchased or upgraded. Unused transfer in one month cannot be carried over to the next month. Web Hosting packages "Premium" and "Business" include unlimited bandiwdth, please see our acceptable usage policy.</p>

                <h4>Money back Guarantee</h4>

                <p>Each of Host3's managed web hosting carries a 30 day unconditional money back guarantee. If you are not completely satisfied with our services within the first 30 days of your service, you will be given a full refund of the contract amount.</p>

                <h4>Server Uptime Guarantee</h4>

                <p>If your Web or Reseller hosting server has downtime that is not within the 99.95% uptime, Host3 will refund 5% of the customer's monthly hosting fees for each 30 minutes of server downtime experienced, up to 100% of the monthly hosting fees. Server downtime is measured from the time the server is 100% unreachable from outside networks, until connectivity has been restored. Any scheduled downtime or network / server maintenance will not count towards this downtime calculation.</p>

                <h4>Network Uptime Guarantee</h4>

                <p>Host3 will refund 5% of the customer's monthly hosting fees for each 30 minutes of network downtime experienced, up to 100% of the monthly hosting fees. Network downtime is measured from the time the network is 100% unreachable until connectivity has been restored. Any scheduled downtime or network maintenance will not count towards this downtime calculation. The 100% Network Uptime guarantee does not apply to any software or hardware.</p>

                <h4>Price Change</h4>

                <p>The amount you pay for hosting will never increase from the date of purchase. We reserve the right to change prices listed on Host3, and the right to increase the amount of resources given to plans at any time.</p>

                <h4>Indemnification</h4>

                <p>Customer agrees that it shall defend, indemnify, save and hold Host3 harmless from any and all demands, liabilities, losses, costs and claims, including reasonable attorney's fees asserted against Host3, its agents, its customers, officers and employees, that may arise or result from any service provided or performed or agreed to be performed or any product sold by customer, its agents, employees or assigns. Customer agrees to defend, indemnify and hold harmless Host3 against liabilities arising out of; (1) any injury to person or property caused by any products sold or otherwise distributed in connection with Host3; (2) any material supplied by customer infringing or allegedly infringing on the proprietary rights of a third party; (3) copyright infringement and (4) any defective products sold to customers from Host3's server.</p>

                <h4>Disclaimer</h4>

                <p>Host3 will not be responsible for any damages your business may suffer. Host3 makes no warranties of any kind, expressed or implied for services we provide. Host3 disclaims any warranty or merchantability or fitness for a particular purpose. This includes loss of data resulting from delays, no deliveries, wrong delivery, and any and all service interruptions caused by Host3 and its employees.</p>

                <h4>Reselling</h4>

                <p>Our web hosting packages are not designed for reselling to other customers, if you require the ability to resell or create unique individual accounts for example agency or freelance work, please contact us to discuss your requirements.</p>

                <h4>Miscellaneous</h4>

                <p>We reserve the right to refuse service to anyone at any time for any reason.</p>

                <h4>Changes to the Terms of Service</h4>

                <p>Host3 reserves the right to revise its policies at any time without notice.</p> 
            </Legal>
            <Talk />
            <Footer />
            <Cookies />
        </Store>
    )
}

export default Terms
