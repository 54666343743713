import React, { useState } from 'react'
import talkStyles from './lets-talk.module.scss'
import { navigate } from "gatsby"

export default () => {
    const [name, setName] = useState('')

    const handleChange = (e) => {
        e.preventDefault()
        setName(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        
        /*fetch('https://api.sendgrid.com/v3/marketing/contacts', {
            method: 'PUT',
            headers: {
                'authorization': 'Bearer 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',
                "content-type": "application/json"
            },
            body: "{\"list_ids\":[\"67de3b63-5264-4212-ac46-6c83e818240a\"],\"contacts\":[{\"email\":\"dachagg@gmail.com\"}]}"
        })
        .then(response => response.text())
        .then(data => {
            console.log('Success:', data)
        })
        .catch((error) => {
            console.error('Error:', error)
        })*/
        
        navigate("/solutions/", { state: { name }})
    }

    return (
        <div className={talkStyles.talk}>
            <div className={talkStyles.talk__wave}></div>

            <div className={"container " + talkStyles.talk__container}>

                <p>Better Hosting <span>Starts Here</span></p>

                <form onSubmit={handleSubmit}>
                    <input type="email" name="email" placeholder="Your email" onChange={handleChange} required />
                    <input type="submit" value="Get Started" className="button button__blue"></input>
                </form>

            </div>

        </div>
    )
}